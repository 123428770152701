/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';

export const getTopbuyers = (params: any, id: number) => {
   return axiosInstance.get(`/v1/accounts/top-buyers/${id}`, {
      params,
   });
};

export const getTopbuyersZK = (params: any, id: number) => {
   return axiosInstance.get(`/v1/accounts/zk/top-buyers/${id}`, {
      params,
   });
};

export const getUserTransactions = (params: any, id: number) => {
   return axiosInstance.get(`/v1/transactions/user-transaction/${id}`, {
      params,
   });
};

export const getStategyUserTransactions = (params: any) => {
   return axiosInstance.get(`/v1/strategy/orders/strategy`, {
      params,
   });
};

export const getUserTransactionsZK = (params: any, id: number) => {
   return axiosInstance.get(`/v1/transactions/zk/user-transaction/${id}`, {
      params,
   });
};