/* eslint-disable no-empty-pattern */
import { createSlice } from '@reduxjs/toolkit';
import { PermissionName, RoleName } from '../../constants/permissions';

interface RolePermissions {
   [role: string]: string[];
}

interface PermissionState {
   rolesPermissions: RolePermissions;
}

const initialState: PermissionState = {
   rolesPermissions: {
      [RoleName.SUPER_ADMIN]: Object.values(PermissionName),
      [RoleName.INTERNAL_ADMIN]: Object.values(PermissionName),
      [RoleName.INTERNAL_MODERATOR]: [
         // Project
         PermissionName.PROJECT_VIEW,
         // PermissionName.PROJECT_MODIFY,
         // PermissionName.PROJECT_CREATE,

         // Monitor
         PermissionName.MONITOR_OVERVIEW,
         PermissionName.MONITOR_USERTRANSACTION,
         PermissionName.MONITOR_TOPBUYER,
         PermissionName.MONITOR_INWALLET,

         // Launch
         PermissionName.LAUNCH_VIEW,
         PermissionName.LAUNCH_CHANGE_CONFIG,

         // Boost
         PermissionName.BOOST_VIEW,
         PermissionName.BOOST_CHANGECONFIG,
         PermissionName.BOOST_RUNPAUSE,

         // Take Profit
         PermissionName.TAKEPROFIT_VIEW,
         PermissionName.TAKEPROFIT_ACTION,

         // MM Generator
         PermissionName.MMLIST_VIEW,
         PermissionName.MMLIST_ACTION,
         PermissionName.MMLIST_CREATE,
         PermissionName.MMGENERATOR_VIEW,
         PermissionName.MMGENERATOR_MODIFY,
         PermissionName.MMGENERATOR_FILE,
         PermissionName.MMGENERATOR_SNIPE,
         PermissionName.MMGENERATOR_BUDGET,

         // Third Party
         PermissionName.ACCESS_PREDICTHUB,
         PermissionName.ACCESS_SYNCSWAP,
         PermissionName.ACCESS_UNISWAP,

         // Company
         PermissionName.COMPANY_VIEW,
         // PermissionName.COMPANY_CREATE,

         // Account
         // PermissionName.ACCOUNT_VIEW
      ],
      [RoleName.EXTERNAL_ADMIN]: [
         // Project
         PermissionName.PROJECT_VIEW,
         // PermissionName.PROJECT_MODIFY,
         // PermissionName.PROJECT_CREATE,

         // Monitor
         PermissionName.MONITOR_OVERVIEW,
         PermissionName.MONITOR_USERTRANSACTION,
         PermissionName.MONITOR_TOPBUYER,
         PermissionName.MONITOR_INWALLET,

         // // Launch
         // PermissionName.LAUNCH_VIEW,
         // PermissionName.LAUNCH_CHANGE_CONFIG,

         // // Boost
         // PermissionName.BOOST_VIEW,
         // PermissionName.BOOST_CHANGECONFIG,
         // PermissionName.BOOST_RUNPAUSE,

         // // Take Profit
         // PermissionName.TAKEPROFIT_VIEW,
         // PermissionName.TAKEPROFIT_ACTION,

         // MM Generator
         PermissionName.MMLIST_VIEW,
         // PermissionName.MMLIST_ACTION,
         // PermissionName.MMLIST_CREATE,
         PermissionName.MMGENERATOR_VIEW,
         // PermissionName.MMGENERATOR_MODIFY,
         PermissionName.MMGENERATOR_FILE,
         PermissionName.MMGENERATOR_SNIPE,
         PermissionName.MMGENERATOR_BUDGET,

         // Third Party
         PermissionName.ACCESS_SYNCSWAP,
         PermissionName.ACCESS_UNISWAP,

         // Company
         // PermissionName.COMPANY_VIEW,

         // Account
         PermissionName.ACCOUNT_VIEW,
         PermissionName.ACCOUNT_MODIFY,
         PermissionName.ACCOUNT_CREATE,
         PermissionName.ACCOUNT_DELETE,

         // Assign
         PermissionName.PROJECT_ASSIGN,
      ],
      [RoleName.EXTERNAL_MODERATOR]: [
         // Project
         PermissionName.PROJECT_VIEW,
         // PermissionName.PROJECT_MODIFY,
         // PermissionName.PROJECT_CREATE,

         // Monitor
         PermissionName.MONITOR_OVERVIEW,
         PermissionName.MONITOR_USERTRANSACTION,
         PermissionName.MONITOR_TOPBUYER,
         PermissionName.MONITOR_INWALLET,

         // // Launch
         // PermissionName.LAUNCH_VIEW,
         // PermissionName.LAUNCH_CHANGE_CONFIG,

         // // Boost
         // PermissionName.BOOST_VIEW,
         // PermissionName.BOOST_CHANGECONFIG,
         // PermissionName.BOOST_RUNPAUSE,

         // // Take Profit
         // PermissionName.TAKEPROFIT_VIEW,
         // PermissionName.TAKEPROFIT_ACTION,

         // MM Generator
         PermissionName.MMLIST_VIEW,
         // PermissionName.MMLIST_ACTION,
         // PermissionName.MMLIST_CREATE,
         PermissionName.MMGENERATOR_VIEW,
         // PermissionName.MMGENERATOR_MODIFY,
         // PermissionName.MMGENERATOR_FILE,
         // PermissionName.MMGENERATOR_SNIPE,
         // PermissionName.MMGENERATOR_BUDGET,

         // Third Party
         // PermissionName.ACCESS_PREDICTHUB,
         PermissionName.ACCESS_SYNCSWAP,
         PermissionName.ACCESS_UNISWAP,

         // Company
         // PermissionName.COMPANY_VIEW,
      ],
      [RoleName.VIEWER_ZKSYNC]: [
         // Project
         PermissionName.PROJECT_VIEW,

         // Monitor
         PermissionName.MONITOR_OVERVIEW, 
         PermissionName.MONITOR_INWALLET,
         PermissionName.MONITOR_USERTRANSACTION,

         // Third Party
         PermissionName.ACCESS_SYNCSWAP,
      ],
   },
};

const permissionSlice = createSlice({
   name: 'permissions',
   initialState,
   reducers: {},
});

export default permissionSlice.reducer;
