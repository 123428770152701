import { DEFAULT_PASSWORD } from "../constants/common"
import { correctIcon, incorrectIcon } from "../constants/svg"

function checkRegexMatch(inputString: string, regexPattern: string | RegExp) {
    const regex = new RegExp(regexPattern)
    return regex.test(inputString)
}

export function checkValidate(type: string, password: string) {
    if (type === 'upperCase') {
        const uppercaseRegex = /^(?=.*[A-Z])/
        if (checkRegexMatch(password, uppercaseRegex)) return true
    }
    if (type === 'special') {
        const specialCharacterRegex = /^(?=.*[!@#$%^&*()_+{}\\[\]:;<>,.?~])/
        if (checkRegexMatch(password, specialCharacterRegex)) return true
    }
    if (type === 'digit') {
        const digitRegex = /^(?=.*\d)/
        if (checkRegexMatch(password, digitRegex)) return true
    }
    if (type === 'minLength') {
        const minLengthRegex = /^.{8,}$/
        if (checkRegexMatch(password, minLengthRegex)) return true
    }
    if (type === 'match') {
        return password !== DEFAULT_PASSWORD
    }
    return false
}


export const getValidateIcon = (type: string, password: string) => {
    if (checkValidate(type, password)) return correctIcon
    return incorrectIcon
}
