/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fakeGetUserApi } from './authAPI';
import { AuthState, User } from './type';
import { AppThunk } from '../store';
import { get, KEYS, remove, set } from '../../utils/localStorage';

const initialState: AuthState = {
   user: get(KEYS.USER),
   isAuthenticated: !!get(KEYS.TOKEN),
   loading: false,
   error: null,
};

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      loginStart: (state) => {
         state.loading = true;
         state.error = null;
      },
      loginSuccess: (state, action: PayloadAction<{ token: string }>) => {
         state.loading = false;
         state.isAuthenticated = true;
         set(KEYS.TOKEN, action.payload.token);
      },
      
      loginFailure: (state, action: PayloadAction<string>) => {
         state.loading = false;
         state.error = action.payload;
      },
      logout: (state) => {
         state.isAuthenticated = false;
         state.user = null;
         remove(KEYS.TOKEN);
         remove(KEYS.USER);
      },
      setUser: (state, action: PayloadAction<User>) => {
         state.isAuthenticated = true;
         set(KEYS.USER, action.payload);
         state.user = action.payload;
      },
   },
});

export const { loginStart, loginSuccess, loginFailure, logout, setUser } =
   authSlice.actions;

export default authSlice.reducer;

export const fetchUser =
   (token: string): AppThunk =>
   async (dispatch: any) => {
      try {
         const user = (await fakeGetUserApi(token)) as User;
         dispatch(setUser(user));
      } catch (err) {
         console.error(err);
      }
   };
