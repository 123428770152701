/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
   LockOutlined,
   UserOutlined,
   LoginOutlined,
   EyeTwoTone,
   EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure, loginStart, setUser } from '../../redux/auth/authSlice';
import { RootState } from '../../redux/store';
import { loginSuccess } from '../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { LoginRequest } from './type/LoginTypes';
import { getProfile, login } from '../../services/authenServices';
import { User } from '../../redux/auth/type';
import delay from '../../utils/delay';
import ModalContactForgetPassword from './ModalContactForgetPassword';
import { UserType } from '../../constants/user';

const LoginComponent = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [isOpenForget, setIsOpenForget] = useState(false)

   const { loading, error } = useSelector((state: RootState) => state.auth);

   const onFinish = async (values: LoginRequest) => {
      dispatch(loginStart());
      try {
        const loginRes = await login(values);
        const token = loginRes?.data?.data?.access_token;
    
        dispatch(loginSuccess({ token }));
    
        const userRes = await getProfile();
        const user = userRes?.data?.data 
        dispatch(setUser(user as User));
    
        const companyId = user.company?.id;
        const targetPath = user?.type === UserType.INTERNAL || !companyId
          ? "/company/projects"
          : `/company/${companyId}/project`;
    
        navigate(targetPath, { replace: true });
      } catch (error: any) {
         console.log(error?.response)
         const errorCode = error?.response?.data?.statusCode
         if (errorCode === 403) {
            dispatch(loginFailure("This account can not access right now."));
         } else {
            dispatch(loginFailure("Incorrect username or password!"));
         }
      }
    };
    



   return (
      <div className="login-screen">
         <div className="login-block shadow-medium">
            <h1 className="login-title">Login</h1>
            <Form
               name="normal_login"
               className="login-form"
               initialValues={{ remember: true }}
               onFinish={onFinish}
            >
               <Form.Item
                  name="username"
                  rules={[
                     { required: true, message: 'Please input your Username!' },
                  ]}
               >
                  <Input
                     size="large"
                     prefix={<UserOutlined className="site-form-item-icon" />}
                     placeholder="Username"
                  />
               </Form.Item>
               <Form.Item
                  name="password"
                  rules={[
                     { required: true, message: 'Please input your Password!' },
                  ]}
               >
                  <Input.Password
                     size="large"
                     prefix={<LockOutlined className="site-form-item-icon" />}
                     placeholder="input password"
                     iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                     }
                  />
               </Form.Item>
               <div className='flex items-center justify-between'>
                  {/* <div className='invisible flex items-center '>
                     <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                           <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                     </Form.Item>
                  </div> */}
                  <div></div>
                  <div className='cursor-pointer text-sm text-[#0CA7EE] mb-[24px]' onClick={() => setIsOpenForget(true)}>Forgot password?</div>

               </div>


               {!!error && (
                  <Alert
                     style={{ marginBottom: '30px' }}
                     message={error}
                     type="error"
                     showIcon
                     closable
                  />
               )}
               <Form.Item>
                  <Button
                     size="large"
                     style={{ width: '100%', gap: '16px' }}
                     loading={loading}
                     icon={<LoginOutlined />}
                     type="primary"
                     htmlType="submit"
                     className="login-form-button"
                  >
                     Log in
                  </Button>
               </Form.Item>
            </Form>
         </div>
         <ModalContactForgetPassword isOpen={isOpenForget} setIsOpen={setIsOpenForget} />
      </div>
   );
};

export default LoginComponent;
