import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/LoadingComponent';
import { RootState, useAppDispatch } from '../../../redux/store';
import { useSelector } from 'react-redux';
import { fetchProjectById } from '../../../redux/projects/projectsSlice';
import ProjectPnLComponent from './ProjectPnLComponent';
import { Button, Tabs } from 'antd';
import ProjectCalculateInWalletComponent from './ProjectCalculateInWalletComponent';
import ProjectUserTransactionComponent from './ProjectUserTransactionComponent';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';
import ProjectTopBuyerComponent from './ProjectTopBuyerComponent';
import UserSvg from '../../../assets/svg/user';
import PermissionAssignComponent from './PermissionAssignComponent';
import { fetchCompanyById } from '../../../redux/companies/companiesSlice';

const ProjectSyncswapMonitorComponent = () => {
   const params = useParams();
   const { project, loading: projectLoading } = useSelector(
      (state: RootState) => state.projects,
   );
   const { company, loading: companyLoading } = useSelector(
      (state: RootState) => state.companies,
   );

   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { hasPermission, isInternalUser } = usePermissions();
   const [isModalOpenAccess, setIsModalOpenAccess] = useState<boolean>(false);

   const projectMonitorBreadcrumbs = isInternalUser
      ? [
           {
              title: 'Project Management',
              link: `/company/projects`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={companyLoading}>
                    {company?.name}
                 </LoadingComponent>
              ),
              link: `/company/${params?.companyId}/project/`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={projectLoading}>
                    {project?.name}
                 </LoadingComponent>
              ),
           },
        ]
      : [
           {
              title: 'All Projects',
              link: `/company/${params?.companyId}/project`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={projectLoading}>
                    {project?.name}
                 </LoadingComponent>
              ),
              link: `/project-syncswap/${params?.id}/monitor`,
           },
        ];

   useEffect(() => {
      if (!project && !projectLoading && params?.id) {
         dispatch(fetchProjectById(+params?.id, navigate));
      }
   }, [dispatch, project, projectLoading]);

   useEffect(() => {
      if (params?.companyId) {
         dispatch(fetchCompanyById(+params?.companyId, navigate));
      }
   }, [dispatch, params?.companyId]);

   return (
      <div className="projects-monitor-component pb-8">
         <div className="flex justify-between items-center">
            <BreadcrumbComponent items={projectMonitorBreadcrumbs} />

            {hasPermission(PermissionName.PROJECT_ASSIGN) && (
               <>
                  <Button
                     type="primary"
                     icon={<UserSvg />}
                     onClick={() => setIsModalOpenAccess(true)}
                  >
                     Assign
                  </Button>
                  <PermissionAssignComponent
                     id={params?.id}
                     isModalOpen={isModalOpenAccess}
                     setIsModalOpen={setIsModalOpenAccess}
                  />
               </>
            )}
         </div>
         <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MONITOR_OVERVIEW) && (
                  <Tabs.TabPane tab="Overview" key="1">
                     <ProjectPnLComponent
                        project={project}
                        projectLoading={projectLoading}
                     />
                  </Tabs.TabPane>
               )}
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MONITOR_TOPBUYER) && (
                  <Tabs.TabPane tab="Top buyers" key="2">
                     <ProjectTopBuyerComponent />
                  </Tabs.TabPane>
               )}
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MONITOR_USERTRANSACTION) && (
                  <Tabs.TabPane tab="User transactions" key="3">
                     <ProjectUserTransactionComponent />
                  </Tabs.TabPane>
               )}

            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MONITOR_INWALLET) && (
                  <Tabs.TabPane tab="In Wallets" key="4">
                     <ProjectCalculateInWalletComponent
                        project={project}
                        projectLoading={projectLoading}
                     />
                  </Tabs.TabPane>
               )}
         </Tabs>
         {/* <ProjectLockSellComponent /> */}
      </div>
   );
};

export default ProjectSyncswapMonitorComponent;
