import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface PrivateRouteProps {
   element: React.ReactElement;
   hasPermission?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
   element,
   hasPermission = true,
}) => {
   const isAuthenticated = useSelector(
      (state: RootState) => state.auth.isAuthenticated,
   );

   const location = useLocation();

   return isAuthenticated && hasPermission ? (
      element
   ) : (
      <Navigate to="/login" state={{ from: location }} replace />
   );
};

export default PrivateRoute;
