/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';
import { AccountChangePasswordForm, AccountCreateForm, AccountUpdateForm } from '../pages/Projects/type/AccountTypes';

export const createAccountApi = (data: AccountCreateForm) => {
   return axiosInstance.post('/v1/auth/register', data);
};

export const editAccountApi = (data: AccountUpdateForm) => {
   return axiosInstance.put('/v1/auth/update', data);
};

export const resetPasswordApi = (userId: string) => {
   return axiosInstance.post('/v1/auth/reset-password', {
      userId
   });
};

export const getAccountsApi = (params?: any) => {
   return axiosInstance.get('/v1/users', {
      params,
   });
};


export const deleteAccountsApi = (id: string) => {
   return axiosInstance.delete('/v1/auth/delete', {
      data: {
         userId: id
      },
   });
};

export const changePasswordApi = (data: AccountChangePasswordForm) => {
   return axiosInstance.post('/v1/auth/change-password', data);
};