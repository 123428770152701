import { RoleName } from '../constants/permissions';

export const roleNameMappings: { [key in RoleName]: string } = {
   [RoleName.SUPER_ADMIN]: 'Super Admin',
   [RoleName.INTERNAL_ADMIN]: 'Internal Admin',
   [RoleName.INTERNAL_MODERATOR]: 'Internal Moderator',
   [RoleName.EXTERNAL_ADMIN]: 'External Admin',
   [RoleName.EXTERNAL_MODERATOR]: 'External Moderator',
   [RoleName.VIEWER_ZKSYNC]: 'Viewer ZKSync',
};

export const isAdmin = (roleName: RoleName): boolean => {
   return [
      RoleName.SUPER_ADMIN,
      RoleName.INTERNAL_ADMIN,
      RoleName.EXTERNAL_ADMIN,
   ].includes(roleName);
};
