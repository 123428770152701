/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
   Button,
   Card,
   Checkbox,
   Col,
   Form,
   Input,
   InputRef,
   message,
   Radio,
   RadioChangeEvent,
   Row,
   Select,
   Space,
   Table,
   TableColumnType,
   TableProps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import {
   DataTypeSyncswapUserTx,
   RangeValue,
   SearchParam,
   SelectCurrency,
   TransactionType,
} from '../type/ProjectTypes';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import InputNumberComponent from '../../../components/InputNumberComponent';
import { validatePositiveNumber } from '../../../utils/validatePositiveNumber';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import {
   DEFAULT_PAGINATE_SIZE,
   ERROR_MESSAGE,
} from '../../../constants/common';
import usePaginationConfig from '../../../hooks/usePaginationConfig';
import formatCurrency from '../../../utils/currencyConverter';
import TransactionTypesRender from '../../../utils/transactionTypesRender';
import amountWithIconRender from '../../../utils/amountWithIconRender';
import useSortOrder from '../../../hooks/useSortOrder';
import { useParams } from 'react-router-dom';
import { transactionDexscreenerScan } from '../../../utils/linkScan';
import rangeAmount from '../../../utils/rangeAmount';
import { ETH_ADDRESS_REGEX } from '../../../constants/regex';
import { dateConverter } from '../../../utils/dateTimeStampConverter';
import {
   DolphinFilter,
   FilterAnimals,
   FilterIconRender,
   FishFilter,
   ShrimpFilter,
   WhaleFilter,
} from '../../../utils/filterIconRender';
import OpenSvg from '../../../assets/svg/open';
import AddressCopyableComponent from '../../../components/AddressCopyableComponent';
import { io, Socket } from 'socket.io-client';
import OverviewInfo from '../../../components/OverviewInfo';
import { getPnlUserTxZK } from '../../../services/projectServices';
import _ from 'lodash';
import LoadingComponent from '../../../components/LoadingComponent';
import HeadingWithTooltipComponent from '../../../components/TitleWithTooltipComponent';
import overviewTextColorRender from '../../../utils/overviewTextColorRender';
import ZKSyncSvg from '../../../assets/svg/zksync';
import moment from 'moment';
import { getUserTransactionsZK } from '../../../services/monitorService';

const SOCKET_SERVER_URL = 'ws://phoenix-socket.aquafox.io/detect';

interface ServerToClientEvents {
   NewTransaction: (data: string) => void;
   chatMessage: (message: string) => void;
}

interface ClientToServerEvents {
   sendCustomEvent: (message: string) => void;
}

type DataIndex = keyof DataTypeSyncswapUserTx;

interface PnlUserTxResponse {
   totalETHBuy: string;
   totalETHSell: string;
   totalUSDBuy: string;
   totalUSDSell: string;
   pnlETH: string;
   pnlUSD: string;
}

const ProjectUserTransactionComponent = () => {
   const [formETH] = Form.useForm();

   const [formToken] = Form.useForm();

   const [formMaker] = Form.useForm();

   const [dataUserTx, setDataUserTx] = useState<DataTypeSyncswapUserTx[]>([]);

   const [searchParams, setSearchParams] = useState<SearchParam[]>([]);

   const [userTXCurrency, setUserTXCurrency] = useState<SelectCurrency>('ETH');

   const [fetchingUserTX, setFetchingUserTX] = useState<boolean>(false);

   const [isLoadingPnl, setLoadingPnl] = useState<boolean>(false);

   const [page, setPage] = useState<number>(1);

   const [total, setTotal] = useState<number>(0);

   const isFetchingTableRef = useRef(false);

   const isFetchingPnlRef = useRef(false);

   const [isFetchingInterval, setFetchingInterval] = useState<boolean>(false);

   const [pnlUserTxData, setPnlUserTxData] = useState<PnlUserTxResponse | null>(
      null,
   );

   const [socket, setSocket] = useState<Socket<
      ServerToClientEvents,
      ClientToServerEvents
   > | null>(null);

   const [isTrigger, setTrigger] = useState<boolean>(false);

   const paginationConfig = usePaginationConfig(total, page, setPage);

   const searchInput = useRef<InputRef>(null);

   const { project } = useSelector((state: RootState) => state.projects);

   const params = useParams();

   const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
      useSortOrder<DataTypeSyncswapUserTx>();
   const tableRef: any = useRef(null);

   const handleTableChange = (
      pagination: any,
      filters: any,
      sorter:
         | SorterResult<DataTypeSyncswapUserTx>
         | SorterResult<DataTypeSyncswapUserTx>[],
   ) => {
      updateSortOrder(sorter);
   };

   const searchParamsConverter = () => {
      const paramsConverter: any = {};
      const eventType = searchParams.find((s) => s.type === 'eventType');
      if (eventType && Array.isArray(eventType.value)) {
         paramsConverter.eventTypes = eventType.value
            .map((x) => x.toLowerCase())
            .join(',');
      }
      const amountEth = searchParams.find((s) => s.type === 'amountEth');
      if (amountEth && amountEth.value && typeof amountEth.value === 'object') {
         if ('min' in amountEth.value && 'max' in amountEth.value) {
            const value = amountEth.value as RangeValue;
            paramsConverter.minEth = value.min;
            paramsConverter.maxEth = value.max;
         }
      }
      const amountToken = searchParams.find((s) => s.type === 'amountToken');
      if (
         amountToken &&
         amountToken.value &&
         typeof amountToken.value === 'object'
      ) {
         const value = amountToken.value as RangeValue;
         paramsConverter.minToken = value.min;
         paramsConverter.maxToken = value.max;
      }
      const makerAddress = searchParams.find((s) => s.type === 'makerAddress');
      if (makerAddress && makerAddress.value) {
         paramsConverter.makerAddress = makerAddress.value;
      }
      const amountUsd = searchParams.find((s) => s.type === 'amountUsd');
      if (amountUsd && amountUsd.value && typeof amountUsd.value === 'string') {
         const value = amountUsd.value as FilterAnimals;
         const range = rangeAmount(value.toLowerCase());
         paramsConverter.minUsd = range.min;
         if (range.max) {
            paramsConverter.maxUsd = range.max;
         }
      }
      // if (amountUsd === 1) {
      //    paramsConverter
      // }
      return paramsConverter;
   };

   const updateSearchParams = (newItem: SearchParam) => {
      setSearchParams((prevParams) => {
         if (newItem.value === undefined) {
            return prevParams.filter((item) => item.type !== newItem.type);
         } else {
            return [
               ...prevParams.filter((item) => item.type !== newItem.type),
               newItem,
            ];
         }
      });
   };

   const handleSearchText = (
      selectedKeys: string[],
      close: FilterDropdownProps['close'],
      dataIndex: DataIndex,
   ) => {
      close();
      updateSearchParams({
         type: dataIndex,
         value: formMaker.getFieldValue('maker'),
      });
   };

   const handleReset = (
      clearFilters: () => void,
      confirm: FilterDropdownProps['confirm'],
      dataIndex: DataIndex,
   ) => {
      formMaker.resetFields();
      clearFilters();
      confirm();
      updateSearchParams({
         type: dataIndex,
         value: undefined,
      });
   };

   const getColumnSearchTextProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeSyncswapUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         close,
      }) => (
         <div
            style={{
               padding: 12,
               paddingTop: 22,
               paddingBottom: 20,
               width: 300,
            }}
            onKeyDown={(e) => e.stopPropagation()}
         >
            <Form
               form={formMaker}
               size="middle"
               onFinish={(values) => {
                  setSelectedKeys(values.maker ? [values.maker] : []);
                  handleSearchText(
                     selectedKeys as string[],
                     confirm,
                     dataIndex,
                  );
               }}
            >
               <Form.Item
                  name="maker"
                  rules={[
                     {
                        required: true,
                        message: 'Please input your address!',
                     },
                     {
                        pattern: ETH_ADDRESS_REGEX,
                        message: 'Please input valid Address!',
                     },
                  ]}
               >
                  <Input
                     ref={searchInput}
                     placeholder={`Search Maker`}
                     value={selectedKeys[0]}
                  />
               </Form.Item>
            </Form>

            <div className="flex justify-between gap-2">
               <Button
                  type="primary"
                  className="w-full"
                  onClick={() => formMaker.submit()}
                  icon={<SearchOutlined />}
                  size="middle"
               >
                  Search
               </Button>
               <Button
                  onClick={() => {
                     clearFilters &&
                        handleReset(clearFilters, close, dataIndex);
                  }}
                  className="w-full"
                  size="middle"
               >
                  Reset
               </Button>
            </div>
         </div>
      ),
      filterIcon: () => FilterIconRender(searchParams, dataIndex, 'text'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) =>
         //   searchedColumn === dataIndex ? (
         //     <Highlighter
         //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
         //       searchWords={[searchText]}
         //       autoEscape
         //       textToHighlight={text ? text.toString() : ''}
         //     />
         //   ) : (
         text,
      //   ),
   });

   const getColumnSearchAnimalSingleProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeSyncswapUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Radio.Group
               disabled={false}
               onChange={(e: RadioChangeEvent) => {
                  const value = e.target.value;
                  setSelectedKeys(value);
                  if (value === 1) {
                     confirm();
                     clearFilters && clearFilters();
                     updateSearchParams({
                        type: 'amountUsd',
                        value: undefined,
                     });
                  } else {
                     confirm();
                     updateSearchParams({
                        type: 'amountUsd',
                        value,
                     });
                  }
               }}
               value={selectedKeys}
            >
               <Space direction="vertical">
                  <Radio value={1}>All</Radio>
                  <Radio value="shrimp">
                     <ShrimpFilter />
                  </Radio>
                  <Radio value="fish">
                     <FishFilter />
                  </Radio>
                  <Radio value="dolphin">
                     <DolphinFilter />
                  </Radio>
                  <Radio value="whale">
                     <WhaleFilter />
                  </Radio>
               </Space>
            </Radio.Group>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, 'amountUsd', 'animal'),
      render: (text) => text,
   });

   const getColumnSearchMultiProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeSyncswapUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         // confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8 }}>
               <Checkbox.Group
                  onChange={(checkedValues) => {
                     const value = checkedValues;
                     setSelectedKeys(value);
                     if (!value?.length) {
                        // confirm();
                        clearFilters && clearFilters();
                        updateSearchParams({
                           type: dataIndex,
                           value: undefined,
                        });
                     } else {
                        // confirm();
                        updateSearchParams({
                           type: dataIndex,
                           value,
                        });
                     }
                  }}
                  value={selectedKeys as string[]}
               >
                  <Space direction="vertical">
                     <Checkbox value="Buy">Buy</Checkbox>
                     <Checkbox value="Sell">Sell</Checkbox>
                  </Space>
               </Checkbox.Group>
            </div>
         </div>
      ),
      filterIcon: () => FilterIconRender(searchParams, dataIndex, 'multiple'),
      render: (text) => text,
   });

   const getColumnSearchETHRangeProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeSyncswapUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         // selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8, width: 300 }}>
               <Form
                  form={formETH}
                  size="middle"
                  onFinish={(values) => {
                     confirm();
                     setSelectedKeys(values);
                     updateSearchParams({
                        type: dataIndex,
                        value: values,
                     });
                  }}
               >
                  <Form.Item
                     name="min"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        prefix={<ZKSyncSvg width={20} height={20} />}
                        placeholder="Min"
                     />
                  </Form.Item>
                  <Form.Item
                     name="max"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (
                                 getFieldValue('min') !== undefined &&
                                 value !== undefined &&
                                 value <= getFieldValue('min')
                              ) {
                                 return Promise.reject(
                                    new Error(
                                       'Max must be greater than to Min',
                                    ),
                                 );
                              }
                              return Promise.resolve();
                           },
                        }),
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        prefix={<ZKSyncSvg width={20} height={20} />}
                        placeholder="Max"
                     />
                  </Form.Item>
                  <div className="flex justify-between gap-2">
                     <Button
                        className="w-full"
                        size="middle"
                        icon={<SearchOutlined />}
                        type="primary"
                        htmlType="submit"
                     >
                        Search
                     </Button>
                     <Button
                        className="w-full"
                        size="middle"
                        onClick={() => {
                           clearFilters && clearFilters();
                           confirm();
                           setSelectedKeys([]);
                           formETH.resetFields();
                           updateSearchParams({
                              type: dataIndex,
                              value: undefined,
                           });
                        }}
                     >
                        Reset
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, dataIndex, 'range'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) => text,
   });

   const getColumnSearchTokenRangeProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeSyncswapUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         // selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8, width: 300 }}>
               <Form
                  form={formToken}
                  size="middle"
                  onFinish={(values) => {
                     confirm();
                     setSelectedKeys(values);
                     updateSearchParams({
                        type: dataIndex,
                        value: values,
                     });
                  }}
               >
                  <Form.Item
                     name="min"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore={project?.name.toLocaleUpperCase()}
                        placeholder="Min"
                     />
                  </Form.Item>
                  <Form.Item
                     name="max"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (
                                 getFieldValue('min') !== undefined &&
                                 value !== undefined &&
                                 value <= getFieldValue('min')
                              ) {
                                 return Promise.reject(
                                    new Error(
                                       'Max must be greater than to Min',
                                    ),
                                 );
                              }
                              return Promise.resolve();
                           },
                        }),
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore={project?.name.toLocaleUpperCase()}
                        placeholder="Max"
                     />
                  </Form.Item>
                  <div className="flex justify-between gap-2">
                     <Button
                        className="w-full"
                        size="middle"
                        icon={<SearchOutlined />}
                        type="primary"
                        htmlType="submit"
                     >
                        Search
                     </Button>
                     <Button
                        className="w-full"
                        size="middle"
                        onClick={() => {
                           clearFilters && clearFilters();
                           confirm();
                           setSelectedKeys([]);
                           formToken.resetFields();
                           updateSearchParams({
                              type: dataIndex,
                              value: undefined,
                           });
                        }}
                     >
                        Reset
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, dataIndex, 'range'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) => text,
   });

   const columnsUserTx: TableProps<DataTypeSyncswapUserTx>['columns'] = [
      {
         title: 'Date',
         dataIndex: 'createdAt',
         key: 'createdAt',
         ...sortColumns('createdAt'),
         render: (value: number) => dateConverter(value),
      },
      {
         title: 'Type',
         dataIndex: 'eventType',
         key: 'eventType',
         responsive: ['sm', 'md', 'lg'],
         ...getColumnSearchMultiProps('eventType'),
         render: (value: TransactionType) => TransactionTypesRender(value),
      },
      {
         title: 'USD',
         dataIndex: 'amountEth',
         key: 'amountEth',
         ...sortColumns('amountEth'),
         ...getColumnSearchAnimalSingleProps('amountEth'),
         render: (_, record) =>
            amountWithIconRender(
               Number(record.amountUsd),
               'USD',
               record?.eventType,
            ),
      },
      {
         title: 'ZK',
         dataIndex: 'amountEth',
         responsive: ['sm', 'md', 'lg'],
         key: 'amountEth',
         ...sortColumns('amountEth'),
         ...getColumnSearchETHRangeProps('amountEth'),
         render: (value: number) => formatCurrency(value, 'USD'),
      },
      {
         title: project?.name,
         dataIndex: 'amountToken',
         key: 'amountToken',
         responsive: ['sm', 'md', 'lg'],
         ...sortColumns('amountToken'),
         ...getColumnSearchTokenRangeProps('amountToken'),
         render: (value: number) => formatCurrency(value, 'TOKEN'),
      },
      {
         title: 'Maker',
         dataIndex: 'makerAddress',
         key: 'makerAddress',
         ...getColumnSearchTextProps('makerAddress'),
         render: (text, record) => <AddressCopyableComponent text={text} />,
      },
      {
         title: 'Txn',
         dataIndex: 'hash',
         align: 'center',
         key: 'hash',
         responsive: ['sm', 'md', 'lg'],
         render: (_, record) => (
            <a
               className="flex justify-center"
               href={transactionDexscreenerScan(
                  project?.pairAddress || '',
                  record?.makerAddress,
                  project ? project.chainId : 1,
               )}
               target="_blank"
               rel="noopener, noreferrer"
            >
               <OpenSvg />
            </a>
         ),
      },
   ];

   useEffect(() => {
      const newSocket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
         SOCKET_SERVER_URL,
         {
            transports: ['websocket', 'polling'],
         },
      );

      newSocket.on('connect', () => {
         console.log('Connected to server:', newSocket.id);
      });

      newSocket.on('disconnect', (reason: string) => {
         console.log('Disconnected from server:', reason);
      });

      setSocket(newSocket);

      // Cleanup when component unmount
      return () => {
         newSocket.close();
      };
   }, []);

   // const sendMessage = () => {
   //    if (socket) {
   //       socket.emit('sendCustomEvent', 'Hello Server!');
   //    }
   // };

   useEffect(() => {
      if (socket) {
         socket.on('NewTransaction', () => {
            if (!isTrigger) {
               setTrigger(true);
               setTimeout(() => setTrigger(false), 4000);
            }
         });
      }
   }, [socket]);

   const fetchUserTX = async () => {
      try {
         const fromDate = moment(
            '2024-12-09 00:00',
            'YYYY-MM-DD HH:mm',
         ).toISOString();

         setFetchingUserTX(true);
         const res = await getUserTransactionsZK(
            {
               page,
               limit: DEFAULT_PAGINATE_SIZE,
               eventType: 'buy,sell',
               accountTypes: 'external',
               orderBy: 'createdAt',
               sortBy: 'desc',
               fromDate,
               ...getSortParams(),
               ...searchParamsConverter(),
            },
            params.id ? +params.id : 0,
         );
         const userTxs = res?.data?.data;
         setDataUserTx(userTxs?.items);
         setTotal(userTxs?.meta?.total);
         setFetchingUserTX(false);
      } catch (error) {
         setFetchingUserTX(false);
         message.error(ERROR_MESSAGE);
      }
   };

   useEffect(() => {
      fetchUserTX();
   }, [page, sortOrder, searchParams]);

   const fetchUserTxPnl = async () => {
      if (!params?.id || isFetchingPnlRef.current) {
         return;
      }
      setLoadingPnl(true);
      try {
         const resPnl = await getPnlUserTxZK(params?.id ? +params.id : 0);
         setLoadingPnl(false);
         setPnlUserTxData(resPnl?.data?.data);
      } catch (err) {
         setLoadingPnl(false);
      }
   };

   useEffect(() => {
      fetchUserTxPnl();
   }, []);

   const fetchBoth = async () => {
      if (isFetchingPnlRef.current || isFetchingTableRef.current) {
         return;
      }
      if (!_.isEmpty(searchParamsConverter())) {
         return;
      }
      try {
         setFetchingInterval(true);
         isFetchingPnlRef.current = true;
         isFetchingTableRef.current = true;

         const fromDate = moment(
            '2024-12-09 00:00',
            'YYYY-MM-DD HH:mm',
         ).toISOString();

         const [dataPnl, dataUserTx] = await Promise.all([
            getPnlUserTxZK(params?.id ? +params.id : 0),
            getUserTransactionsZK(
               {
                  page,
                  limit: DEFAULT_PAGINATE_SIZE,
                  eventType: 'buy,sell',
                  accountTypes: 'external',
                  orderBy: 'createdAt',
                  sortBy: 'desc',
                  fromDate,
                  ...getSortParams(),
                  ...searchParamsConverter(),
               },
               params.id ? +params.id : 0,
            ),
         ]);
         setPnlUserTxData(dataPnl?.data?.data);
         const userTxs = dataUserTx?.data?.data;
         setDataUserTx(userTxs?.items);
         setTotal(userTxs?.meta?.total);
         setFetchingInterval(false);
         isFetchingPnlRef.current = false;
         isFetchingTableRef.current = false;
      } catch (err) {
         isFetchingPnlRef.current = false;
         isFetchingTableRef.current = false;
         setFetchingInterval(false);
      }
   };

   useEffect(() => {
      const intervalId = setInterval(fetchBoth, 5000);
      return () => clearInterval(intervalId);
   }, [sortOrder, searchParams, page]);

   return (
      <>
         <div className="flex gap-3 mt-[7px] mb-[23px] justify-between items-center flex-wrap">
            <Title level={3} className="!mb-0 max-sm:w-full">
               {`${project?.name} Transactions`}
            </Title>
            <div className="flex gap-3 items-center max-sm:flex-row-reverse">
               {isFetchingInterval && (
                  <SyncOutlined spin style={{ fontSize: 24 }} />
               )}
               <Select
                  value={userTXCurrency}
                  onChange={(value: SelectCurrency) => setUserTXCurrency(value)}
                  className="w-24 shadow-small rounded-[10px]"
               >
                  <Select.Option value="USD">USDT</Select.Option>
                  <Select.Option value="ETH">ZK</Select.Option>
               </Select>
               {!_.isEmpty(searchParamsConverter()) && (
                  <SyncOutlined
                     onClick={() => {
                        setPage(1);
                        setSearchParams([]);
                        tableRef.current?.clearFilters?.();
                     }}
                     style={{ fontSize: 24 }}
                  />
               )}
            </div>
         </div>
         {isTrigger && (
            <div className="fixed z-50 bottom-0 left-0 right-0  pointer-events-none">
               <img
                  className="w-full h-[100vh]"
                  src={`/images/gif/gif-whale-2.gif?${new Date().getTime()}`}
                  alt="hehe"
               />
            </div>
         )}
         <Title level={5} className="!mb-[16px]">
            Summary
         </Title>
         <Card>
            {/* <Highlighter
            searchWords={['dog', 'cat']}
            textToHighlight="The dog is chasing the cat. Or perhaps they're just playing?"
         /> */}
            <Row className="!mb-0" gutter={[20, 20]}>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>
                        Bought
                     </HeadingWithTooltipComponent>
                     {userTXCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={pnlUserTxData?.totalUSDBuy || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={pnlUserTxData?.totalETHBuy || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>
                        Sold
                     </HeadingWithTooltipComponent>
                     {userTXCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={pnlUserTxData?.totalUSDSell || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={pnlUserTxData?.totalETHSell || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={8}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>
                        Profit & Loss
                     </HeadingWithTooltipComponent>
                     {userTXCurrency === 'USD' ? (
                        <OverviewInfo
                           className={overviewTextColorRender(
                              pnlUserTxData?.pnlUSD,
                           )}
                           amountUsd={pnlUserTxData?.pnlUSD || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           className={overviewTextColorRender(
                              pnlUserTxData?.pnlETH,
                           )}
                           amountZK={pnlUserTxData?.pnlETH || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            Detail
         </Title>
         <Table
            ref={tableRef}
            loading={fetchingUserTX}
            columns={columnsUserTx}
            dataSource={dataUserTx}
            pagination={paginationConfig}
            bordered
            onChange={handleTableChange}
            rowClassName={() => 'cursor-pointer'}
            scroll={{ x: 'max-content' }}
         />
      </>
   );
};

export default ProjectUserTransactionComponent;
