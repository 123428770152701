export const ERROR_MESSAGE = 'Something went wrong. Try again later';

export const DEFAULT_PAGINATE_SIZE = 20;

export const TIME_ZONE = 7;

export const DEFAULT_DATETIME_FORMAT = 'HH:mm:ss DD-MM-YYYY';

export const DEFAULT_DATETIME_FORMAT_FILE = 'HH[h]mm DD-MM-YYYY';

export const DEFAULT_PASSWORD = 'Password@123'


export const AQUAFOX_ID = '1';
export const AQUAFOX_NAME = 'AquaFox JSC';