/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';
import { CompanyCreateForm } from '../pages/Projects/type/CompanyTypes';

export const getCompaniesApi = (params: any) => {
   return axiosInstance.get('/v1/company', {
      params,
   });
};

export const createCompanyApi = (data: CompanyCreateForm) => {
   return axiosInstance.post('/v1/company', data);
};

export const getCompanyByIdApi = (id: number) => {
   return axiosInstance.get(`/v1/company/${id}`);
};

export const editCompanyApi = (id: string, data: CompanyCreateForm) => {
   return axiosInstance.put(`/v1/company/${id}`, {
      data
   });
};

export const deleteCompanyApi = (id: string) => {
   return axiosInstance.delete(`/v1/company/${id}`);
};
