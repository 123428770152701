import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { getCompanyByIdApi } from '../../services/companyServices';
import { Company } from './type';

type CompanyState = {
   company: Company | null;
   loading: boolean;
   error: string | null;
};

const initialState: CompanyState = {
   company: null,
   loading: false,
   error: null,
};

const companiesSlice = createSlice({
   name: 'companies',
   initialState,
   reducers: {
      setCompany(state, action: PayloadAction<Company | null>) {
         state.company = action.payload;
         state.loading = false;
         state.error = null;
      },
      setLoading(state, action: PayloadAction<boolean>) {
         state.loading = action.payload;
      },
      setError(state, action: PayloadAction<string | null>) {
         state.error = action.payload;
         state.loading = false;
      },
   },
});

export const { setCompany, setLoading, setError } = companiesSlice.actions;

export default companiesSlice.reducer;

export const fetchCompanyById =
   (id: number, navigate: (path: string) => void): AppThunk =>
   async (dispatch) => {
      dispatch(setLoading(true));
      try {
         const res = await getCompanyByIdApi(id);
         const company = res?.data?.data as Company;
         dispatch(setCompany(company));
      } catch (err) {
         navigate('/company/projects');
         dispatch(setCompany(null));
      } finally {
         dispatch(setLoading(false));
      }
   };
