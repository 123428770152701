/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';

export const getStrategies = (params: any) => {
   return axiosInstance.get('/v1/projects', {
      params,
   });
};

export const getDataOverview = (id: any) => {
   return axiosInstance.get(`/v1/strategy/statistics/${id}`);
};

// export const createProject = (data: FieldTypeProjectCreateForm) => {
//    return axiosInstance.post('/v1/projects', data);
// };

export const getStrateryByID = (id: number) => {
   return axiosInstance.get(`/v1/strategy?strategyID=${id}`);
};

export const getDataBot = (id: any) => {
   return axiosInstance.get(`/v1/strategy/statistics/monitoring-bot/${id}`);
};
