export enum UserStatus {
  ACTIVE = 'active',
  SUSPEND = 'suspend',
}


export enum UserType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}
