import React, { RefObject, useState } from 'react';
import { Avatar, Dropdown, MenuProps, Modal } from 'antd';
import {
   UserOutlined,
   LogoutOutlined,
   ExclamationCircleFilled,
   LockOutlined,
} from '@ant-design/icons';
import { Header } from 'antd/es/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { logout } from '../redux/auth/authSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CaretDown from '../assets/svg/caretDown';
import usePermissions from '../hooks/usePermissions';
import { PermissionName } from '../constants/permissions';
import ModalChangePasswordAccount from '../pages/Accounts/ModalChangePasswordAccount';
import { burgerIcon } from '../constants/svg';

interface HeaderComponentProps {
   headerRef: RefObject<HTMLDivElement>;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ headerRef }) => {
   const { user, isAuthenticated } = useSelector(
      (state: RootState) => state.auth,
   );
   const location = useLocation();
   const isActive = (slug: string) => {
      for (const item of slug.split(",")) {
         if (location.pathname.includes(item)) {
            return true
         }
      }
      return false
   }
   const { hasPermission } = usePermissions();

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false)

   const appLogout = () => {
      Modal.confirm({
         title: 'Logout',
         icon: <ExclamationCircleFilled />,
         content: 'Do you want to logout?',
         onOk() {
            dispatch(logout());
            navigate('/login');
         },
      });
   };

   const openModalChangePaswword = () => {
      setIsOpenModalChangePassword(true)
   }

   const items: MenuProps['items'] = [
      {
         className: 'sm:!hidden',
         key: '3',
         label: user?.username || 'user',
      },
      {
         key: '4',
         label: 'Change Password',
         icon: <LockOutlined />,
         onClick: openModalChangePaswword,
      },
      {
         key: '5',
         danger: true,
         label: 'Logout',
         icon: <LogoutOutlined />,
         onClick: appLogout,
      },
   ];

   const navs = [
   ]

   if (hasPermission(PermissionName.ACCOUNT_VIEW)) {
      navs.push(
         {
            name: "Project Management",
            link: hasPermission(PermissionName.COMPANY_VIEW) ? "/company/projects" : `/company/${user?.company?.id}/project`,
            slug: "project,strategy"
         }
      )
      navs.push(
         {
            name: "Account Management",
            link: hasPermission(PermissionName.COMPANY_VIEW) ? "/company/accounts" : `/company/${user?.company?.id}/account`,
            slug: "account"
         }
      )
   }


   const itemsNavs: MenuProps['items'] = navs.map((item) => ({
      key: item.slug,
      label: <Link key={item.link} to={item.link} className={`${isActive(item.slug) ? "!text-primary font-semibold" : ''}`}>
         {item.name}
      </Link>
   }))

   return (
      <Header className="header !bg-[#F5F5F5]" ref={headerRef}>
         <div className="container flex justify-between items-center">
            <Link to="/" className="logo-container">
               <img
                  className="logo-img cursor-pointer max-sm:!h-[32px]"
                  src="/images/common/logo_aquaphoenix.png"
               />
            </Link>

            <div className='flex gap-[40px] justify-center max-md:hidden'>
               {
                  navs.map((item) => (
                     <Link key={item.link} to={item.link} className={`${isActive(item.slug) ? "text-primary font-semibold" : ''}`}>
                        {item.name}
                     </Link>
                  ))
               }
            </div>

            <div className='flex gap-[10px] items-center'>
               {isAuthenticated ? (
                  <div className="flex gap-4 items-center">
                     <Dropdown
                        menu={{ items }}
                        placement="bottomRight"
                        className="header-dropdown"
                     >
                        <div className="flex gap-[10px] cursor-pointer items-center leading-0">
                           <Avatar size={38} icon={<UserOutlined />} />
                           <div className="user-name max-sm:hidden">
                              {user?.username || 'user'}
                           </div>
                           <div className='max-md:hidden'>
                              <CaretDown />
                           </div>
                        </div>
                     </Dropdown>
                  </div>
               ) : <div></div>}
               {
                  navs.length > 0 &&
                  <div className='md:hidden' >
                     <Dropdown
                        menu={{ items: itemsNavs }}
                        placement="bottomRight"
                        className="header-dropdown"
                     >
                        <div className="flex gap-[10px] cursor-pointer items-center leading-0">
                           {burgerIcon}
                        </div>
                     </Dropdown>
                  </div>
               }
            </div>

         </div>
         <ModalChangePasswordAccount isOpen={isOpenModalChangePassword} setIsOpen={setIsOpenModalChangePassword} />
      </Header>
   );
};

export default HeaderComponent;
