/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, Input, message, Modal, notification, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import { EyeInvisibleOutlined, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { ERROR_MESSAGE } from '../../constants/common';
import { createCompanyApi, getCompaniesApi } from '../../services/companyServices';
import { DataTypeCompany } from '../Projects/type/CompanyTypes';
import usePermissions from '../../hooks/usePermissions';
import { PermissionName } from '../../constants/permissions';
import { checkValidate, getValidateIcon } from '../../utils/checkValidPassword';
import { ErrorCallback } from 'typescript';
import { deleteAccountsApi } from '../../services/accountServices';

interface ModalContactForgetPasswordProps {
    isOpen: boolean,
    setIsOpen: any,
}

const ModalContactForgetPassword = (props: ModalContactForgetPasswordProps) => {
    const { isOpen, setIsOpen } = props

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <Modal
            title={<div className="w-full text-center">Forget Password</div>}
            open={isOpen}
            okText="Close"
            width={500}
            onOk={closeModal}
            onCancel={closeModal}
            footer={[
                <Button
                    key="ok"
                    style={{ backgroundColor: '#0CA7EE', borderColor: '#0CA7EE' }}
                    onClick={closeModal}
                >
                    Close
                </Button>,
            ]}
        >
            <div className="flex flex-col items-center justify-center">
                <img src="/images/common/mail.png" width={100} height={100} />
                <div className="text-[#2F2E31] my-[20px]">
                    Please contact AquaPhoenix customer support to reset password.
                </div>
            </div>
        </Modal>

    );
};

export default ModalContactForgetPassword;
