import React from 'react';
import './App.css';
import './styles/index.scss';
import { Layout, message } from 'antd';
import {
   BrowserRouter as Router,
   useNavigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { setupInterceptors } from './axios/axiosInstance';
import LayoutComponent from './pages/LayoutComponent';

message.config({
   // top: 100,
   // duration: 2,
   maxCount: 1,
   // rtl: true,
   // prefixCls: 'my-message',
});

function App() {
   const SetupInterceptors = () => {
      const navigate = useNavigate();
      setupInterceptors(store, navigate);
      return null;
   };

   return (
      <Router>
         <Layout>
            <Provider store={store}>
               <SetupInterceptors />
               <LayoutComponent/>
            </Provider>
         </Layout>
      </Router>
   );
}

export default App;
