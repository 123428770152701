import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

const AccountListByCompanyComponent = lazy(
   () => import('../AccountListByCompanyComponent'),
);

// const CompanyAccountListComponent = lazy(
//    () => import('../CompanyAccountListComponent'),
// );

const AccountRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <Routes>
            <Route
               path="/"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(PermissionName.ACCOUNT_VIEW)}
                     element={<AccountListByCompanyComponent />}
                  />
               }
            />
            {/* <Route
               path=":id"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(
                        PermissionName.ACCOUNT_VIEW,
                     )}
                     element={<AccountListByCompanyComponent />}
                  />
               }
            /> */}
         </Routes>
      </Suspense>
   );
};

export default AccountRouterComponent;
