/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flex, Form, Input, message, Modal, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { createCompanyApi, getCompaniesApi } from '../../services/companyServices';
import { checkValidate, getValidateIcon } from '../../utils/checkValidPassword';
import { changePasswordApi, resetPasswordApi } from '../../services/accountServices';
import { checkValidationForm } from '../../utils/form';

interface ModalChangePasswordAccountProps {
    isOpen: boolean,
    setIsOpen: any,
}


const ModalChangePasswordAccount = (props: ModalChangePasswordAccountProps) => {
    const { isOpen, setIsOpen } = props

    const [form] = Form.useForm();
    const [currentPassword, setCurrentPassword] = useState('')

    const [password, setPassword] = useState('')
    const [retypePassword, setRetypePassword] = useState('')

    useEffect(() => {
        if (isOpen) {
            form.resetFields()
        }
    }, [isOpen])

    const isValidatePassword = useMemo(
        () => checkValidate('upperCase', password)
            && checkValidate('special', password)
            && checkValidate('digit', password)
            && checkValidate('minLength', password)
        ,
        [password],
    )

    const onHandleChange = (e: any) => {
        const name = e.target.name
        const value = e.target.value
        if (name === 'password') {
            setPassword(value)
        }
        if (name === 'retype_password') {
            form.validateFields(['retype_password'])
            setRetypePassword(value)
        }
        if (name === 'current_password') {
            setCurrentPassword(value)
        }

    }

    const handleChangePassword = async () => {
        const isValidateForm = await checkValidationForm(form)
        if (!isValidateForm || !isValidatePassword) return
        try {
            const res = await changePasswordApi({
                currentPassword: currentPassword,
                newPassword: password,
                confirmPassword: retypePassword
            });
            closeModalAdd()
            notification.success({
                message: "Password Changed Successfully",
                placement: 'topRight',
                style: {
                    borderRadius: '10px',
                    background: '#E3FFF4',
                    boxShadow: '0px 2px 6px 0px rgba(59, 59, 66, 0.36)',
                },
            })
        }
        catch (err: any) {
            console.log(err?.response)
            const errorMessage = err?.response?.data?.message
            if (errorMessage?.includes('E10002')) {
                message.error("Incorrect current password.")
            } else {
                message.error("Password Changed Failed")
            }
        }

    }

    const closeModalAdd = () => {
        setIsOpen(false)
    }

    const openModalAdd = () => {
        setIsOpen(true)
    }

    useEffect(() => {
        if (!isOpen) {
            form.resetFields()
            setCurrentPassword('')
            setPassword('')
            setRetypePassword('')
        }
    }, [isOpen])

    return (
        <Modal
            title={<div className='w-full text-center'>Change Password</div>}
            open={isOpen}
            okText="Save"
            width={500}
            onOk={handleChangePassword}
            onCancel={closeModalAdd}
        >
            <Form
                form={form}
                name="dynamic_form"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    key="Current Password"
                    label="Current Password"
                    name="current_password"
                    rules={[
                        {
                            required: true,
                            message: `Current Password is required!`,
                        },
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                        name="current_password"
                        onChange={onHandleChange}
                    />
                </Form.Item>
                <Form.Item
                    key="New Password"
                    label="New Password"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: `New Password is required!`,
                        },
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                        name="password"
                        onChange={onHandleChange}
                    />
                </Form.Item>
                {
                    password &&
                    <div>
                        <ul className={`cp-checklist remove-stype`}>
                            <li>
                                <>{getValidateIcon('upperCase', password)}</>
                                At least one uppercase letter
                            </li>
                            <li>
                                <>{getValidateIcon('special', password)}</>
                                At least one special character
                            </li>
                            <li>
                                <>{getValidateIcon('digit', password)}</>
                                At least one digit
                            </li>
                            <li>
                                <>{getValidateIcon('minLength', password)}</>
                                A minimum of 8 characters
                            </li>
                        </ul>
                    </div>
                }
                <Form.Item
                    key="Confirm Password"
                    label="Confirm Password"
                    name="retype_password"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (password === retypePassword) {
                                    return Promise.resolve()
                                }
                                return Promise.reject("Password doesn't match")
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        iconRender={(visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                        name="retype_password"
                        onChange={onHandleChange}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalChangePasswordAccount;
