import { useSelector } from 'react-redux';
import { User } from '../redux/auth/type';
import { RootState } from '../redux/store';
import { useMemo } from 'react';
import { UserType } from '../constants/user';

const usePermissions = () => {
   const user: User | null = useSelector((state: RootState) => state.auth.user);
   const rolesPermissions = useSelector(
      (state: RootState) => state.permissions.rolesPermissions,
   );

   const hasPermission = useMemo(() => {
      return (permission: string): boolean => {
         if (!user) return false;
         for (const role of (user?.roles || [])) {
            const rolePermissions = rolesPermissions[role.name];
            if (
               rolePermissions && rolePermissions.includes(permission)
            ) {
               return true;
            }
         }
         return false;
      };
   }, [user, rolesPermissions]);

   const isInternalUser = useMemo(() => {
      if (!user) return false;
      return user?.type === UserType.INTERNAL
   }, [user]);

   const hasRole = useMemo(() => {
      return (roleName: string): boolean => {
         if (!user?.roles?.length) return false;
         (user.roles).forEach((role) => {
            if (role.name === roleName) {
               return true
            }
         })
         return false
      }
   }, [user]);

   return { hasPermission, isInternalUser, hasRole };
};

export default usePermissions;
