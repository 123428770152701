/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
   Button,
   Card,
   Col,
   Form,
   Input,
   InputRef,
   message,
   Row,
   Select,
   Table,
   TableColumnType,
   TableProps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import {
   DataTypeTopBuyer,
   SearchParam,
   SelectCurrency,
} from '../type/ProjectTypes';
import {
   DEFAULT_PAGINATE_SIZE,
   ERROR_MESSAGE,
} from '../../../constants/common';
import { getTopbuyersZK } from '../../../services/monitorService';
import usePaginationConfig from '../../../hooks/usePaginationConfig';
import useSortOrder from '../../../hooks/useSortOrder';
import { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import formatCurrency from '../../../utils/currencyConverter';
import { useParams } from 'react-router-dom';
import { addressLinkScan } from '../../../utils/linkScan';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import OpenSvg from '../../../assets/svg/open';
import AddressCopyableComponent from '../../../components/AddressCopyableComponent';
import { ETH_ADDRESS_REGEX } from '../../../constants/regex';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { FilterIconRender } from '../../../utils/filterIconRender';
import OverviewInfo from '../../../components/OverviewInfo';
import { getPnlTopbuyerZK } from '../../../services/projectServices';
import _ from 'lodash';
import LoadingComponent from '../../../components/LoadingComponent';
import HeadingWithTooltipComponent from '../../../components/TitleWithTooltipComponent';
import ZKSyncSvg from '../../../assets/svg/zksync';

type DataIndex = keyof DataTypeTopBuyer;
interface PnlTopbuyerResponse {
  totalUserZK: string;
  totalUserUSD: string;
  totalUserToken: string;
  balancePoolContract: BalancePoolContract;
  tokenPriceByZK: string;
  tokenPriceByUSD: string;
  ZKPrice: string;
}

interface BalancePoolContract {
  tokenBalance: string;
  zkBalance: string;
}

const ProjectTopBuyerComponent = () => {
   const [topbuyersCurrency, setTopbuyersCurrency] =
      useState<SelectCurrency>('ETH');

   const [dataTopBuyer, setDataTopBuyer] = useState<DataTypeTopBuyer[]>([]);

   const [fetchingTopbuyers, setFetchingTopbuyers] = useState<boolean>(false);

   const [page, setPage] = useState<number>(1);

   const [total, setTotal] = useState<number>(0);

   const [pnlTopbuyerData, setPnlTopbuyerData] =
      useState<PnlTopbuyerResponse | null>(null);

   const { project } = useSelector((state: RootState) => state.projects);

   const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
      useSortOrder<DataTypeTopBuyer>();

   const [formAddress] = Form.useForm();

   const searchInput = useRef<InputRef>(null);

   const [searchParams, setSearchParams] = useState<SearchParam[]>([]);

   const params = useParams();

   const [isLoadingPnl, setLoadingPnl] = useState<boolean>(false);

   const paginationConfig = usePaginationConfig(total, page, setPage);

   const isFetchingTableRef = useRef(false);

   const isFetchingPnlRef = useRef(false);

   const [isFetchingInterval, setFetchingInterval] = useState<boolean>(false);

   const searchParamsConverter = () => {
      const paramsConverter: any = {};
      const address = searchParams.find((s) => s.type === 'address');
      if (address && address.value) {
         paramsConverter.address = address.value;
      }
      return paramsConverter;
   };

   const updateSearchParams = (newItem: SearchParam) => {
      setSearchParams((prevParams) => {
         if (newItem.value === undefined) {
            return prevParams.filter((item) => item.type !== newItem.type);
         } else {
            return [
               ...prevParams.filter((item) => item.type !== newItem.type),
               newItem,
            ];
         }
      });
   };

   const handleTableChange = (
      pagination: any,
      filters: any,
      sorter: SorterResult<DataTypeTopBuyer> | SorterResult<DataTypeTopBuyer>[],
   ) => {
      updateSortOrder(sorter);
   };

   const handleSearchText = (
      selectedKeys: string[],
      close: FilterDropdownProps['close'],
      dataIndex: DataIndex,
   ) => {
      close();
      updateSearchParams({
         type: dataIndex,
         value: formAddress.getFieldValue('address'),
      });
   };

   const handleReset = (
      clearFilters: () => void,
      confirm: FilterDropdownProps['confirm'],
      dataIndex: DataIndex,
   ) => {
      formAddress.resetFields();
      clearFilters();
      confirm();
      updateSearchParams({
         type: dataIndex,
         value: undefined,
      });
   };

   const getColumnSearchTextProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeTopBuyer> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         close,
      }) => (
         <div
            style={{
               padding: 12,
               paddingTop: 22,
               paddingBottom: 20,
               width: 300,
            }}
            onKeyDown={(e) => e.stopPropagation()}
         >
            <Form
               form={formAddress}
               size="middle"
               onFinish={(values) => {
                  setSelectedKeys(values.address ? [values.address] : []);
                  handleSearchText(
                     selectedKeys as string[],
                     confirm,
                     dataIndex,
                  );
               }}
            >
               <Form.Item
                  name="address"
                  rules={[
                     {
                        required: true,
                        message: 'Please input your address!',
                     },
                     {
                        pattern: ETH_ADDRESS_REGEX,
                        message: 'Please input valid Address!',
                     },
                  ]}
               >
                  <Input
                     ref={searchInput}
                     placeholder="Search Address"
                     value={selectedKeys[0]}
                  />
               </Form.Item>
            </Form>

            <div className="flex justify-between gap-2">
               <Button
                  type="primary"
                  className="w-full"
                  onClick={() => formAddress.submit()}
                  icon={<SearchOutlined />}
                  size="middle"
               >
                  Search
               </Button>
               <Button
                  onClick={() => {
                     clearFilters &&
                        handleReset(clearFilters, close, dataIndex);
                  }}
                  className="w-full"
                  size="middle"
               >
                  Reset
               </Button>
            </div>
         </div>
      ),
      filterIcon: () => FilterIconRender(searchParams, dataIndex, 'text'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) =>
         //   searchedColumn === dataIndex ? (
         //     <Highlighter
         //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
         //       searchWords={[searchText]}
         //       autoEscape
         //       textToHighlight={text ? text.toString() : ''}
         //     />
         //   ) : (
         text,
      //   ),
   });

   const columnsTopBuyer: TableProps<DataTypeTopBuyer>['columns'] = [
      {
         title: 'Address',
         dataIndex: 'address',
         key: 'address',
         ...getColumnSearchTextProps('address'),
         render: (text) => <AddressCopyableComponent text={text} />,
      },
      {
         title: 'Bought',
         dataIndex: 'boughtETH',
         key: 'boughtETH',
         responsive: ['lg'],
         ...sortColumns('boughtETH'),
         render: (_, record) => (
            <div className="flex gap-1 justify-end">
               <div>
                  {topbuyersCurrency === 'USD' ? (
                     '$'
                  ) : (
                     <ZKSyncSvg width={20} height={20} />
                  )}
               </div>
               {formatCurrency(
                  topbuyersCurrency === 'USD'
                     ? +record?.boughtUSD
                     : +record?.boughtETH,
                  topbuyersCurrency,
               )}
            </div>
         ),
      },
      {
         title: 'Sold',
         dataIndex: 'soldETH',
         key: 'soldETH',
         responsive: ['lg'],
         ...sortColumns('soldETH'),
         render: (_, record) => (
            <div className="flex gap-1 justify-end">
               <div>
                  {topbuyersCurrency === 'USD' ? (
                     '$'
                  ) : (
                     <ZKSyncSvg width={20} height={20} />
                  )}
               </div>
               {formatCurrency(
                  topbuyersCurrency === 'USD'
                     ? +record?.soldUSD
                     : +record?.soldETH,
                  topbuyersCurrency,
               )}
            </div>
         ),
      },
      {
         title: 'P&L',
         dataIndex: 'pnlEth',
         key: 'pnlEth',
         ...sortColumns('pnlEth'),
         render: (_, record) => (
            <div className="flex gap-1 justify-end">
               <div>
                  {topbuyersCurrency === 'USD' ? (
                     '$'
                  ) : (
                     <ZKSyncSvg width={20} height={20} />
                  )}
               </div>
               {formatCurrency(
                  topbuyersCurrency === 'USD'
                     ? +record?.pnlUsd
                     : +record?.pnlEth,
                  topbuyersCurrency,
               )}
            </div>
         ),
      },
      {
         title: 'ZK',
         dataIndex: 'balancewWeth',
         key: 'balanceWeth',
         ...sortColumns('balanceEth'),
         responsive: ['sm', 'md', 'lg'],
         render: (_, record) => (
            <div className="flex gap-1">
               <div>
                  <ZKSyncSvg width={20} height={20} />
               </div>
               {formatCurrency(+record?.balanceEth, 'USD')}
            </div>
         ),
      },
      {
         title: project?.name,
         dataIndex: 'balanceToken',
         key: 'balanceToken',
         responsive: ['sm', 'md', 'lg'],
         ...sortColumns('balanceToken'),
         render: (item) => (
            <div className="flex gap-1">
               {`${formatCurrency(item, 'TOKEN')}`}
            </div>
         ),
      },
      {
         title: 'Wallet',
         dataIndex: 'address',
         key: 'address',
         align: 'center',
         responsive: ['lg'],
         render: (address: string) => (
            <a
               className="flex justify-center"
               href={addressLinkScan(address, project ? project.chainId : 1)}
               target="_blank"
               rel="noopener, noreferrer"
            >
               <OpenSvg />
            </a>
         ),
      },
   ];

   const fetchTopbuyerPnl = async () => {
      if (!params?.id || isFetchingPnlRef.current) {
         return;
      }
      isFetchingPnlRef.current = true;
      setLoadingPnl(true);
      try {
         const resPnl = await getPnlTopbuyerZK(params?.id ? +params.id : 0);
         setLoadingPnl(false);
         isFetchingPnlRef.current = false;
         setPnlTopbuyerData(resPnl?.data?.data);
      } catch (err) {
         isFetchingPnlRef.current = false;
         setLoadingPnl(false);
      }
   };

   const fetchTopbuyers = async () => {
      try {
         setFetchingTopbuyers(true);
         isFetchingTableRef.current = true;
         const res = await getTopbuyersZK(
            {
               page,
               limit: DEFAULT_PAGINATE_SIZE,
               orderBy: 'pnlEth',
               sortBy: 'asc',
               accountTypes: 'external',
               ...getSortParams(),
               ...searchParamsConverter(),
            },
            params.id ? +params.id : 0,
         );
         const topbuyers = res?.data?.data;
         setDataTopBuyer(topbuyers?.items);
         setTotal(topbuyers?.meta?.total);
         setFetchingTopbuyers(false);
         isFetchingTableRef.current = false;
      } catch (error) {
         setFetchingTopbuyers(false);
         isFetchingTableRef.current = false;
         message.error(ERROR_MESSAGE);
      }
   };

   useEffect(() => {
      fetchTopbuyers();
   }, [page, sortOrder, searchParams]);

   useEffect(() => {
      fetchTopbuyerPnl();
   }, []);

   const fetchBoth = async () => {
      if (isFetchingPnlRef.current || isFetchingTableRef.current) {
         return;
      }

      if (!_.isEmpty(searchParamsConverter())) {
         return;
      }

      try {
         setFetchingInterval(true);
         isFetchingPnlRef.current = true;
         isFetchingTableRef.current = true;
         const [dataPnl, dataTopbuyer] = await Promise.all([
            getPnlTopbuyerZK(params?.id ? +params.id : 0),
            getTopbuyersZK(
               {
                  page,
                  limit: DEFAULT_PAGINATE_SIZE,
                  orderBy: 'pnlEth',
                  sortBy: 'asc',
                  accountTypes: 'external',
                  ...getSortParams(),
                  ...searchParamsConverter(),
               },
               params.id ? +params.id : 0,
            ),
         ]);
         setPnlTopbuyerData(dataPnl?.data?.data);
         const topbuyers = dataTopbuyer?.data?.data;
         setDataTopBuyer(topbuyers?.items);
         setTotal(topbuyers?.meta?.total);
         setFetchingInterval(false);
         isFetchingPnlRef.current = false;
         isFetchingTableRef.current = false;
      } catch (err) {
         isFetchingPnlRef.current = false;
         isFetchingTableRef.current = false;
         setFetchingInterval(false);
      }
   };

   useEffect(() => {
      const intervalId = setInterval(fetchBoth, 5000);
      return () => clearInterval(intervalId);
   }, [sortOrder, searchParams]);

   return (
      <>
         <div className="flex gap-3 justify-between items-center mt-[7px] mb-[23px] flex-wrap">
            <Title level={3} className="!mb-0 max-sm:w-full">
               {`${project?.name} Top buyers`}
            </Title>
            <div className="flex gap-3 items-center max-sm:flex-row-reverse">
               {isFetchingInterval && (
                  <SyncOutlined spin style={{ fontSize: 24 }} />
               )}
               <Select
                  value={topbuyersCurrency}
                  onChange={(value: SelectCurrency) =>
                     setTopbuyersCurrency(value)
                  }
                  className="w-24 shadow-small rounded-[10px]"
               >
                  <Select.Option value="USD">USDT</Select.Option>
                  <Select.Option value="ETH">ZK</Select.Option>
               </Select>
            </div>
         </div>
         <Title level={5} className="!mb-[16px]">
            Summary
         </Title>
         <Card>
            <Row className="!mb-5" gutter={[20, 20]}>
               <Col xs={24} sm={12} md={12}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>
                        ZK in user&apos;s wallet
                     </HeadingWithTooltipComponent>
                     {topbuyersCurrency === 'USD' ? (
                        <OverviewInfo
                           amountUsd={pnlTopbuyerData?.totalUserUSD || '0'}
                        />
                     ) : (
                        <OverviewInfo
                           amountZK={pnlTopbuyerData?.totalUserZK || '0'}
                        />
                     )}
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={12}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>{`${project?.name} in user's wallet`}</HeadingWithTooltipComponent>
                     <OverviewInfo
                        usdSymbolHidden={true}
                        amountUsd={pnlTopbuyerData?.totalUserToken || '0'}
                     />
                  </LoadingComponent>
               </Col>
               <Col xs={24} sm={12} md={12}>
                  <LoadingComponent loading={isLoadingPnl}>
                     <HeadingWithTooltipComponent>{`${project?.name} price`}</HeadingWithTooltipComponent>
                     {topbuyersCurrency === 'USD' ? (
                        <OverviewInfo
                           tokenPriceUSD={
                              pnlTopbuyerData?.tokenPriceByUSD || '0'
                           }
                        />
                     ) : (
                        <OverviewInfo
                           tokenPriceZK={
                              pnlTopbuyerData?.tokenPriceByZK || '0'
                           }
                        />
                     )}
                  </LoadingComponent>
               </Col>
            </Row>
         </Card>
         <Title level={5} className="!my-[16px]">
            Detail
         </Title>
         <Table
            loading={fetchingTopbuyers}
            columns={columnsTopBuyer}
            dataSource={dataTopBuyer}
            bordered
            pagination={paginationConfig}
            rowClassName={() => 'cursor-pointer'}
            onChange={handleTableChange}
            scroll={{ x: 'max-content' }}
         />
      </>
   );
};

export default ProjectTopBuyerComponent;
