import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import usePermissions from '../hooks/usePermissions';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const user = useSelector((state: RootState) => state.auth.user);
  const { isInternalUser } = usePermissions();
  const location = useLocation();

  const companyId = user?.company?.id;

  return !isAuthenticated || !user ? (
    element
  ) : (
    <Navigate
      to={isInternalUser || !companyId ? "/company/projects" : `/company/${companyId}/project`}
      state={{ from: location }}
      replace
    />
  );
};


export default PublicRoute;