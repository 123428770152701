/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Select } from 'antd';
import { ERROR_MESSAGE } from '../../../constants/common';
import LoadingComponent from '../../../components/LoadingComponent';
import {
   addAccessProject,
   listProjectAccess,
   listProjectCanAccess,
   removeAccessProject,
} from '../../../services/projectServices';
import { ProjectAccess } from '../type/ProjectTypes';
import CancelCircle from '../../../assets/svg/cancelCircle';
import { isAdmin } from '../../../utils/roleNameMappings';
import { MapRoleToName, RoleName } from '../../../constants/permissions';

interface PermissionAssignComponentPros {
   isModalOpen: boolean;
   id: any;
   setIsModalOpen: (value: boolean) => void;
}

interface CanAccessUser {
   createdBy: null;
   updatedBy: null;
   deletedBy: null;
   createdAt: string;
   updatedAt: string;
   deletedAt: null;
   id: string;
   username: string;
   status: string;
   type: string;
   roles: Role[];
}

interface Role {
   name: string;
}

const { Option } = Select;

const PermissionAssignComponent: React.FC<PermissionAssignComponentPros> = ({
   isModalOpen,
   setIsModalOpen,
   id,
}) => {
   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const [selectedValues, setSelectedValues] = useState<null | string>(null);
   const [canAccessUser, setCanAccessUser] = useState<CanAccessUser[]>([]);
   const [accessingUser, setAccessingUser] = useState<boolean>(false);
   const [accessUser, setAccessUser] = useState<CanAccessUser[]>([]);
   const [fetchingUserAccess, setFetchingUserAccess] = useState<boolean>(false);

   const handleChange = (value: string) => {
      setSelectedValues(value);
   };

   const fetchUsers = async () => {
      try {
         setFetchingUserAccess(true);

         const [canAccessResponse, accessResponse] = await Promise.all([
            listProjectCanAccess(id),
            listProjectAccess(id),
         ]);
         let canAccess = canAccessResponse?.data?.data || []
         const access = accessResponse?.data?.data || []
         canAccess = canAccess.filter((x: any) => !access.find((y: any) => x.id === y.id)).filter((item: { id: string }, index: number, self: any[]) =>
            index === self.findIndex((t: { id: any; }) => t.id === item.id)
          );
         setCanAccessUser(canAccess);
         setAccessUser(access);
         setFetchingUserAccess(false);
      } catch (error) {
         setFetchingUserAccess(false);
         message.error(ERROR_MESSAGE);
      }
   };

   const handleAccessProject = async (userId: string, isAdd = true) => {
      if (!userId) {
         message.error('Please select user!');
         return;
      }
      setAccessingUser(true);
      try {
         const body: ProjectAccess = {
            projectId: id,
            userId,
         };
         isAdd ? await addAccessProject(body) : await removeAccessProject(body);
         message.success(`${isAdd ? 'Add' : 'Remove'} user successfully!`);
         setAccessingUser(false);
         setSelectedValues(null);
         fetchUsers();
      } catch (error) {
         setAccessingUser(false);
         message.error(ERROR_MESSAGE);
      }
   };

   useEffect(() => {
      if (isModalOpen) {
         fetchUsers();
      }
   }, [isModalOpen]);

   return (
      <Modal
         title={<div className="text-center">Assign Permission</div>}
         open={isModalOpen}
         className="pb-0"
         onCancel={handleCancel}
         footer={false}
      >
         <div className="flex justify-between items-center gap-3 mt-3">
            <Select
               loading={fetchingUserAccess}
               allowClear
               style={{ width: '100%' }}
               placeholder="Please select"
               value={selectedValues}
               onChange={handleChange}
            >
               {canAccessUser.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                     <div className="flex justify-between items-center w-full px-2">
                        <div className="font-semibold">{item?.username}</div>
                        <div className="text-[#BEBEC9]">
                           {MapRoleToName.find((x) => x.key === item?.roles?.[0].name)?.label || ''}
                        </div>
                     </div>
                  </Option>
               ))}
            </Select>
            <Button
               type="primary"
               onClick={() => handleAccessProject(selectedValues as string)}
            >
               Add
            </Button>
         </div>
         <LoadingComponent
            className="mt-2"
            loading={fetchingUserAccess || accessingUser}
         >
            {accessUser.map((item) => (
               <div
                  key={item?.id}
                  className="flex justify-between items-center h-[42px]"
               >
                  <div className="font-semibold">{item?.username}</div>
                  <div
                     className={`${!isAdmin(item?.roles?.[0].name as RoleName) ? '' : 'text-[#BEBEC9] '}flex gap-2 justify-between items-center`}
                  >
                     {MapRoleToName.find((x) => x.key === item?.roles?.[0].name)?.label || ''}

                     {!isAdmin(item?.roles?.[0].name as RoleName) && (
                        <div
                           className="cursor-pointer"
                           onClick={() => handleAccessProject(item?.id, false)}
                        >
                           <CancelCircle />
                        </div>
                     )}
                  </div>
               </div>
            ))}
         </LoadingComponent>
      </Modal>
   );
};

export default PermissionAssignComponent;
